import React from "react"
import {Link} from "gatsby"
import Navigation from "../components/navigationModule"
import SEO from "../components/seo"
import Styles from "../components/projects.module.css"
import Footer from "../components/footer"
import {isEmptyArray, isEmptyString, isNullUndefined, isObject} from "../util/util";
import get from 'lodash/get';
import ScrollAnimation from 'react-animate-on-scroll'
import BackgroundImage from "react-background-image";

const window = require("global/window");

let itemsLength = 0;

class Projects extends React.Component {

    constructor(props) {
        super(props);
        this.projectItemsList = get(this, 'props.data.allContentfulProjectItem.edges');
        // console.log('projectItemsList original', this.projectItemsList);
        if (itemsLength === 0) {
            this.projectItemsList = this.projectItemsList.splice(0, (this.projectItemsList.length / 2));// dirty hack to remove duplicates
            itemsLength = this.projectItemsList.length;
        }
    }

    state = {
        selectedProjectCategory: 'all'
    };

    static projectCategories = {
        EDUCATIONAL: 'educational',
        OFFICE_OUTFIT: 'office_fit_outs',
        HOUSING: 'housing_and_mixed_use',
        HOTEL_LEISURE: 'hotel_and_leisure',
        MEDICAL: 'medical_and_health',
        COMMERCIAL: 'commercial',
        SOCIAL_AND_CULTURAL: 'social_and_cultural',
        TRANSACTION_ADVISORY: 'transaction_advisory',
        FORENSIC_AUDIT_PROJECTS: 'forensic_audit_projects',
    };

    _filterProject = (selected) => {
        if (!isNullUndefined(window) && !isNullUndefined(window.location)) {
            window.event.preventDefault();//don't navigate to home page
            this.setState({selectedProjectCategory: selected}, () => {
                let currentLocation = window.location.href;
                currentLocation = '#' + selected;
                window.location.href = currentLocation;
            });
        }
    };

    _selectedProjectCategory = () => {

        if (!isNullUndefined(window) && !isNullUndefined(window.location)) {

            let currentLocation = window.location.href;
            let selectedProjectCategory = this.state.selectedProjectCategory;

            for (let key in Projects.projectCategories) {
                let selected = Projects.projectCategories[key];
                if (currentLocation.toUpperCase().includes(selected.toUpperCase())) {
                    selectedProjectCategory = selected;
                }
            }
            return selectedProjectCategory;

        }

        return '';

    };

    _bubbleSortProjectItems(toSortList, toSortListLength) {

        let temp;
        let iteration;
        let index;
        let sortedList = [];

        for (iteration = 1; iteration < toSortListLength; iteration++) {
            for (index = 0; index < toSortListLength - iteration; index++)
                if (
                    isObject(toSortList[index]) &&
                    isObject(toSortList[index+1]) &&
                    toSortList[index].node.rank > toSortList[index + 1].node.rank
                ) {
                    temp = toSortList[index];
                    //NOT IDEAL, BUT WORKS: because of FP principle that function arguments should not be mutated
                    toSortList[index] = toSortList[index + 1];
                    toSortList[index + 1] = temp;
                    //IDEAL: because of FP principle that function arguments should not be mutated
                    sortedList.push(toSortList[index + 1]);
                } else {
                    //KEEPING UP WITH IDEAL: because of FP principle that function arguments should not be mutated
                    sortedList.push(toSortList[index]);
                }
        }

        return toSortList;
        //IDEAL: because of FP principle that function arguments should not be mutated
        // return sortedList;

    }

    render() {

        let getClass = Projects;
        let projectItemsList = null;

        const projectsPageContent = get(this, 'props.data.allContentfulProjectPageContentProjectsPageTopContentRichTextNode.nodes')[0]
        let currentLocation = !isNullUndefined(window) && !isNullUndefined(window.location) && window.location.href;
        // console.log('#### selectedProjectCategory ###', this.state.selectedProjectCategory);
        // console.log('#### currentLocation ###', currentLocation);

        let selectedProjectCategory = this.state.selectedProjectCategory;

        for (let key in getClass.projectCategories) {
            let selected = getClass.projectCategories[key];
            if (!isEmptyString(currentLocation) && currentLocation.toUpperCase().includes(selected.toUpperCase())) {
                selectedProjectCategory = selected;
            }
        }
        let projects = null;
        if (!isEmptyString(selectedProjectCategory)) {
            if (selectedProjectCategory === getClass.projectCategories.ALL)
                projects = this.projectItemsList;
            else {
                projects = this.projectItemsList && this.projectItemsList.filter(item => {
                    if(item.node.category === "transaction_advisory_services" && selectedProjectCategory === "transaction_advisory") return true // can't find unchanged instance of transaction_advisory_services, so comparing with new value and returning matches (DIRTY HACK!)
                    return item.node.category === selectedProjectCategory;
                });
            }
        }
        //order projects by ranks
        projects = !isEmptyArray(projects) && this._bubbleSortProjectItems(projects, projects.length);
        // console.log('#### sorted projects ###', projects);

        projectItemsList = projects && projects.map((item, i) => {
            return (
                <ProjectItemCard
                    key={item.node.slug}
                    projectItem={item.node}
                    slug={item.node.slug}
                />
            );
        });

        return (
            <>
                < SEO title="Projects"/>
                <Navigation/>
                <section>

                    <div className={Styles.Projects}>
                        <div className="overlay"></div>
                        <div className="row">
                            <div className="col-md-12 col-12 col-sm-12">
                                <div className={Styles.text}>

                                    <h2>
                                        Projects
                                    </h2>
                                    <p>
                                        EXPERIENCE & CREATIVITY
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div className="container projects_page">
                            <div className="row">
                                <div className="col-12">

                                    <div className={Styles.filter_container}>
                                        <div className="container">
                                            <div className="row">

                                                <div className="col-md-12">
                                                <p className={"align-center"}>
                                                        {projectsPageContent.content[0].content[0].value}
                                                    </p>
                                                    
                                                    <div className={Styles.filter}>
                                                        <div className="row">

                                                            <div className="col-md-12 col-sm-12 col-12 m-hide-animation">
                                                                <ul>
                                                                    
                                                                        <li aria-label="Commercial"
                                                                            onClick={() => this._filterProject('commercial')}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                position: selectedProjectCategory === getClass.projectCategories.COMMERCIAL ? 'relative' : '',
                                                                                overflow: selectedProjectCategory === getClass.projectCategories.COMMERCIAL ? 'visible' : '',
                                                                                background: selectedProjectCategory === getClass.projectCategories.COMMERCIAL ? '#091b27' : ''
                                                                            }}
                                                                            className={
                                                                                selectedProjectCategory === getClass.projectCategories.COMMERCIAL ? 'active' : ''
                                                                            }
                                                                        >
                                                                            Commercial & Industrial
                                                                        </li>
                                                                    
                                                                    
                                                                        <li aria-label="Educational"
                                                                            onClick={() => this._filterProject('educational')}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                position: selectedProjectCategory === getClass.projectCategories.EDUCATIONAL ? 'relative' : '',
                                                                                overflow: selectedProjectCategory === getClass.projectCategories.EDUCATIONAL ? 'visible' : '',
                                                                                background: selectedProjectCategory === getClass.projectCategories.EDUCATIONAL ? '#091b27' : ''
                                                                            }}
                                                                            className={
                                                                                selectedProjectCategory === getClass.projectCategories.EDUCATIONAL ? 'active' : ''
                                                                            }
                                                                        >
                                                                            Educational
                                                                        </li>
                                                                    
                                                                    
                                                                        <li aria-label="Medical & Health"
                                                                            onClick={() => this._filterProject('medical_and_health')}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                position: selectedProjectCategory === getClass.projectCategories.MEDICAL ? 'relative' : '',
                                                                                overflow: selectedProjectCategory === getClass.projectCategories.MEDICAL ? 'visible' : '',
                                                                                background: selectedProjectCategory === getClass.projectCategories.MEDICAL ? '#091b27' : ''
                                                                            }}
                                                                            className={
                                                                                selectedProjectCategory === getClass.projectCategories.MEDICAL ? 'active' : ''
                                                                            }
                                                                        >
                                                                            Medical & Health
                                                                        </li>
                                                                    
                                                                    
                                                                        <li aria-label="Housing & Mixed-use"
                                                                            onClick={() => this._filterProject('housing_and_mixed_use')}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                position: selectedProjectCategory === getClass.projectCategories.HOUSING ? 'relative' : '',
                                                                                overflow: selectedProjectCategory === getClass.projectCategories.HOUSING ? 'visible' : '',
                                                                                background: selectedProjectCategory === getClass.projectCategories.HOUSING ? '#091b27' : ''
                                                                            }}
                                                                            className={
                                                                                selectedProjectCategory === getClass.projectCategories.HOUSING ? 'active' : ''
                                                                            }
                                                                        >
                                                                            Housing & Mixed-use
                                                                        </li>
                                                                    
                                                                    
                                                                        <li aria-label="Hotel & Leisure"
                                                                            onClick={() => this._filterProject('hotel_and_leisure')}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                position: selectedProjectCategory === getClass.projectCategories.HOTEL_LEISURE ? 'relative' : '',
                                                                                overflow: selectedProjectCategory === getClass.projectCategories.HOTEL_LEISURE ? 'visible' : '',
                                                                                background: selectedProjectCategory === getClass.projectCategories.HOTEL_LEISURE ? '#091b27' : ''
                                                                            }}
                                                                            className={
                                                                                selectedProjectCategory === getClass.projectCategories.HOTEL_LEISURE ? 'active' : ''
                                                                            }
                                                                        >
                                                                            Hotel & Leisure
                                                                        </li>
                                                                    
                                                                    
                                                                        <li aria-label="Office fit outs"
                                                                            onClick={() => this._filterProject('office_fit_outs')}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                position: selectedProjectCategory === getClass.projectCategories.OFFICE_OUTFIT ? 'relative' : '',
                                                                                overflow: selectedProjectCategory === getClass.projectCategories.OFFICE_OUTFIT ? 'visible' : '',
                                                                                background: selectedProjectCategory === getClass.projectCategories.OFFICE_OUTFIT ? '#091b27' : ''
                                                                            }}
                                                                            className={
                                                                                selectedProjectCategory === getClass.projectCategories.OFFICE_OUTFIT ? 'active' : ''
                                                                            }
                                                                        >
                                                                            Office fit outs
                                                                        </li>
                                                                    
                                                                    
                                                                        <li aria-label="social_and_cultural"
                                                                            onClick={() => this._filterProject('social_and_cultural')}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                position: selectedProjectCategory === getClass.projectCategories.SOCIAL_AND_CULTURAL ? 'relative' : '',
                                                                                overflow: selectedProjectCategory === getClass.projectCategories.SOCIAL_AND_CULTURAL ? 'visible' : '',
                                                                                background: selectedProjectCategory === getClass.projectCategories.SOCIAL_AND_CULTURAL ? '#091b27' : ''
                                                                            }}
                                                                            className={
                                                                                selectedProjectCategory === getClass.projectCategories.SOCIAL_AND_CULTURAL ? 'active' : ''
                                                                            }
                                                                        >
                                                                            Social & Cultural
                                                                        </li>
                                                                    
                                                                    
                                                                        <li aria-label="transction_and_advisory"
                                                                            onClick={() => this._filterProject('transaction_advisory')}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                position: selectedProjectCategory === getClass.projectCategories.TRANSACTION_ADVISORY ? 'relative' : '',
                                                                                overflow: selectedProjectCategory === getClass.projectCategories.TRANSACTION_ADVISORY ? 'visible' : '',
                                                                                background: selectedProjectCategory === getClass.projectCategories.TRANSACTION_ADVISORY ? '#091b27' : ''
                                                                            }}
                                                                            className={
                                                                                selectedProjectCategory === getClass.projectCategories.TRANSACTION_ADVISORY ? 'active' : ''
                                                                            }
                                                                        >
                                                                            Transaction Advisory
                                                                        </li>
                                                                    
                                                                    
                                                                        <li aria-label="forestic_audit_projects"
                                                                            onClick={() => this._filterProject('forensic_audit_projects')}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                position: selectedProjectCategory === getClass.projectCategories.FORENSIC_AUDIT_PROJECTS ? 'relative' : '',
                                                                                overflow: selectedProjectCategory === getClass.projectCategories.FORENSIC_AUDIT_PROJECTS ? 'visible' : '',
                                                                                background: selectedProjectCategory === getClass.projectCategories.FORENSIC_AUDIT_PROJECTS ? '#091b27' : ''
                                                                            }}
                                                                            className={
                                                                                selectedProjectCategory === getClass.projectCategories.FORENSIC_AUDIT_PROJECTS ? 'active' : ''
                                                                            }
                                                                        >
                                                                            Forensic Audit Projects
                                                                        </li>
                                                                    

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                {projectItemsList}

                            </div>
                        </div>
                    </div>

                </section>
                <Footer/>
            </>
        )
    }
}

export function ProjectItemCard(props) {

    let {projectItem, slug} = props;

    // console.log('projectItem: ', projectItem);

    return (

        <div className="col-md-6 col-lg-6 col-sm-12 col-12 project_listing">
            <ScrollAnimation animateIn="fadeIn">
                <div className={Styles.grid}>

                    <div className={Styles.box}>
                        <BackgroundImage src={projectItem.image.file.url}>
                            <div className={Styles.bgImg}>
                            </div>
                        </BackgroundImage>

                        <div className={Styles.box_content}>

                            <h2 className={Styles.title}>{projectItem.name && projectItem.name.substring(0, projectItem.name.indexOf(' '))}
                                <span>{projectItem.name && projectItem.name.substring(projectItem.name.indexOf(' '), projectItem.name.length)}</span>
                            </h2>
                            <span className={Styles.post}>{
                                projectItem.shortDescription &&
                                projectItem.shortDescription}
                        </span>
                            <ul className={Styles.icon}>
                                <li>
                                    <Link
                                        className="button auto float_none"
                                        title={projectItem.name}
                                        aria-label="View more"
                                        to={`/project/${slug}`}
                                    >View more</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </ScrollAnimation>
        </div>

    );

}

export default Projects;

export const query = graphql`
    {
        allContentfulProjectPageContentProjectsPageTopContentRichTextNode {
            nodes {
              content {
                content {
                  value
                }
              }
            }
          }
        allContentfulProjectItem {
            edges {
                node {


                    image {
                        file {
                            url
                        }
                    }
                    name


                    shortDescription
                    projectDescription {
                        content {
                            content {
                                value
                            }
                        }
                    }

                    seniorStaffInvolvedAndFunctionsPerformed {
                        content {
                            content {
                                value
                            }
                        }
                    }
                    servicesProvidedDescription {
                        content {
                            content {
                                value
                            }
                        }
                    }
                    category
                    projectImages {
                        file {
                            url
                        }
                    }
                    slug
                    rank
                }
            }
        }
    }
`;
